import React, { useState } from "react"

import Logo from "../assets/web_resources/logo-white.png"

import Root from "../components/root"
import classes from "./terms-conditions.module.css"

import { useMediaQuery } from "react-responsive"
import Dropdown from "../components/Dropdown"
import Layout from "../components/layout"

const TermsConditionsPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" })
  const [isDropdownOpen, setisDropdownOpen] = useState(false)

  return (
    <Layout>
      <Root className={classes.root}>
        {!isDropdownOpen && (
          <div className={classes.logo} onClick={e => setisDropdownOpen(true)}>
            <img src={Logo} alt="" />
          </div>
        )}

        {isDropdownOpen && <Dropdown white2={true} />}

        <div
          className={classes.container}
          onClick={e => setisDropdownOpen(false)}
        >
          <div className={classes.heading}>
            <span className={classes.headSpan}>Mad</span> Jon's
          </div>
          <div className={classes.subHeading}>
            <span className={classes.headSpan}>TERMS</span>
            <span className={classes.headSpan}>& {isMobile && <br />}</span>
            CONDITIONS
          </div>
          <div className={classes.text}>
            <p>Effective date: April 01, 2021</p>
            <p>
              MAD JON PRODUCTIONS SRL (“us”, “we”, or “our”) operates the
              <span>https://themadjon.com/</span>
              website (the “Service”).
            </p>
            <p>
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data.
            </p>
            <p>
              We use your data to provide and improve the Service. By using the
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy Policy, terms used in this Privacy Policy have the same
              meanings as in our Terms and Conditions, accessible from{" "}
              <span>https://themadjon.com/</span>
            </p>
            <p>
              You certify that you are a person at least 13 years of age. The
              Website may only be used or accessed through an electronic device
              controlled of you at all times
            </p>
            <p>
              By using or accessing The MAD JON website (the “Website”,”Site”),
              You agree to be bound and abide by the terms and conditions of
              this Terms of Use Agreement (“Agreement”). If you are entering
              into this Agreement on behalf of a company, organization or
              another legal entity, you are agreeing to this Agreement for that
              entity and representing that you have the authority to bind such
              entity to this Agreement, in which case the term “you” will refer
              to such entity. If you do not agree to this Terms of Use
              Agreement, you should not access or use the Website.
            </p>
            <p>
              {" "}
              MAD JON PRODUCTIONS SRL (referred to as “MAD JON”.” THE MAD
              JON”,“US”, “WE”) reserves the right to change or modify this
              Agreement, and any policy or guideline incorporated by reference
              at any time at its sole discretion.
            </p>
            <p>
              You (referred to as “Customer”) accept our Terms of Service,
              detailed herein, upon clicking the checkbox in our checkout
              process (which is required to complete the form). You agree that
              you’ve reviewed and understand the content within our website
              outlining details on how our service works and our scope.
            </p>
            <p>
              {" "}
              Any changes or modification will be posted by MAD JON to
              <span>https://themadjon.com/terms-and-conditions/</span>
              and become effective upon posting of the revisions on this
              document. You are responsible for regularly reviewing the Site to
              obtain timely notice of such changes or modifications. Your
              continued use of the Services following MAD JON posting of any
              changes or modifications will constitute your acceptance of such
              changes or modifications. IF YOU DO NOT AGREE TO THE TERMS OF ANY
              MODIFICATION, DO NOT CONTINUE TO USE THE SERVICES AND IMMEDIATELY
              PROVIDE NOTICE OF CANCELLATION OF THIS AGREEMENT.
            </p>
            <p>
              Use – Your orders and any extras will be charged in steps, one at
              the beginning of the collaboration or, but not limited to, project
              for ensuring the devotion and commitment of the client to the work
              that its about to be done and at the end of the collaboration,
              period or, but not limited to, project that has been done for the
              client and its about to be sent to him by digital channels only
              after the payment for completion is done by the client. MAD JON
              works on one video at a time. Turnaround times are not guaranteed,
              but a majority of our customers get a first draft for the first
              revision within 2-3 business days.
            </p>
            <p>
              Cancellation – You may cancel your project, collaboration with MAD
              JON at any time before the end of the then-current project,
              collaboration. By canceling the project, collaboration you agree
              to relinquish any payment done to MAD JON for starting the
              project. To cancel your project please email us{" "}
              <span>office@themadjon.com</span> . Upon cancellation, you will
              continue to have access to the MAD JON services and your files
            </p>
            <p>
              Your account and access to the services provided via the site may
              be suspended in the event of non-payment of applicable fees. You
              represent and warrant to MAD JON that such payment information is
              accurate and that you are authorized to use the payment
              instrument. You will promptly update your account information with
              any changes (for example, a change in your billing address or
              credit card expiration date) that may occur. MAD JON may change
              its fees from time to time by posting the changes on the Site
              without prior notice.
            </p>
            <p>
              Limitations and Abuse of Service – MAD JON is designed for the
              reasonable use of a single individual. We are not meant to replace
              a full-time video editor so use your common sense! If we find that
              you are not in alignment with our model, sharing accounts/logins,
              violate our core values, or are abusing our services or team in
              any way, including using our services for illegal or
              adult/pornographic materials, we reserve the right to cancel your
              account.
            </p>
            <p>
              We don’t provide the following services
              <div>
                1. <span className={classes.spanDash}> Branding.</span>
              </div>{" "}
              <div>
                2. <span className={classes.spanDash}>Logo design.</span>
              </div>
              <div>
                {" "}
                3.{" "}
                <span className={classes.spanDash}>
                  Custom or complex 2D/3D animations from scratch.
                </span>
              </div>
            </p>
            <p>
              Output files – While we do our best to minimize any mistakes, due
              to the nature of creative design we don’t guarantee all files
              delivered will be 100% error free. Upon file delivery you agree to
              review and proof all files for any errors or omissions and notify
              our team if any changes/corrections are needed. We will do our
              best to rush any edits to correct the mistakes. MAD JON is not
              responsible or liable for any losses or expenses incurred from
              errors or omissions.
            </p>
            <p>
              {" "}
              Final files will be delivered for revisions and for approval
              containing the MAD JON watermark, after you make the final payment
              for the project, MAD JON will deliver the final output file
              without its watermark.
            </p>
            <p>
              Usually 5-7 days is the average time of completion of a project
              within raw footage length of 120 – 180 minutes or less, however 7
              days may be exceeded in the eventuality that the raw footage
              length exceeds 180 minutes and the project presents a high grade
              of complexity such as:
              <div>
                -
                <span className={classes.spanDash}>
                  multiple final videos to be delivered
                </span>
              </div>
              <div>
                -
                <span className={classes.spanDash}>
                  high volume of text to be added
                </span>
              </div>
              <div>
                -
                <span className={classes.spanDash}>
                  high complexity of color grading
                </span>
              </div>
              <div>
                -{" "}
                <span className={classes.spanDash}>
                  long rendering times due to high complex edits containing
                  motion graphics, texts and other visual elements.
                </span>
              </div>
              <div>
                -
                <span className={classes.spanDash}>
                  4K or higher resolution raw footage
                </span>
              </div>
              <div>
                -<span className={classes.spanDash}>multi-cam projects</span>
              </div>
            </p>
            <p>
              {" "}
              Ownership of Content - Customer maintains the ownership rights in
              any User Content that such Customer submits to the site and the
              finished product MAD JON produces as well.
            </p>
            <p>
              {" "}
              Copyrights - We will always assume you own the copyright of all
              material you upload and edit a video with the files you provide.
            </p>
            <p>
              Grant of Limited Rights to MAD JON - By submitting a Task/project
              to MAD JON, the Customer gives us the rights to User Content to
              the extent necessary to complete the Task. Each Customer
              authorizes us to retain archival copies of all User Content that
              such Customer has submitted. By default, you agree to provide MAD
              JON with a non-exclusive right and license to publish your work in
              our portfolio, website logo block, social media, email, or other
              communication efforts. If you would like to revoke this right,
              please notify us in writing to <span>office@themadjon.com</span> .
            </p>
            <p>
              {" "}
              Work and delivery output – While we can accept unlimited requests
              and 3 rounds of revisions, our output volume depends on many
              factors, namely depending on the total request volume and
              complexity. We will do our best to work with you to accommodate
              any priority items and your timelines but our suggestion is to not
              use our service for time sensitive requests. Your right to use the
              MAD JON services shall terminate upon your breach of any term of
              this Agreement.
            </p>
            <p>
              Limitation of liability – We will not be liable to you or any
              third party in any event for any loss (financially or otherwise)
              or any damages arising from or relating to this Agreement. You
              acknowledge and agree that your use of this site is at your own
              discretion and risk. BECAUSE SOME JURISDICTIONS DO NOT ALLOW
              LIMITATIONS ON IMPLIED WARRANTIES, OR LIMITATIONS OF LIABILITY FOR
              CONSEQUENTIAL OR INCIDENTAL DAMAGES, THESE LIMITATIONS MAY NOT
              APPLY TO YOU. IN NO EVENT SHALL THE TOTAL LIABILITY OF MAD JON
              UNDER THIS AGREEMENT EXCEED FIFTY DOLLARS ($50), REGARDLESS OF THE
              CAUSE OF ACTION, IN TORT, CONTRACT, OR OTHERWISE.
            </p>
            <p>
              It is our policy to terminate relationships regarding content with
              third parties who repeatedly infringe the copyrights of others.
            </p>
            <p>
              This Agreement shall be governed and construed in accordance with
              the laws of the Romanian country and the European Union without
              giving effect to conflict of law principles. You and MAD JON agree
              to submit to the personal and exclusive jurisdiction of the state
              and courts located within Bucharest County, Romania for the
              purpose of litigating all disputes.
            </p>
            <p>
              Contact Us <br /> If you have any questions or suggestions
              regarding our Terms & Conditions, please contact us at
              <span>office@themadjon.com</span>.
            </p>
          </div>
        </div>
      </Root>
    </Layout>
  )
}

export default TermsConditionsPage
